// @flow

import * as React from 'react';
import { hasPassiveEventsSupport } from '../utils/features-detector';

let interactionOrTimeoutDetected_ = false;

// TODO: subscriptions
export const forceNonInteractive = () => {
  interactionOrTimeoutDetected_ = true;
};
// Used for lighthouse perf tricks
export const useAnyUserInteractionOrTimeout = (
  timeout: null | number,
): boolean => {
  const [
    interactionOrTimeoutDetected,
    setInteractionOrTimeoutDetected,
  ] = React.useState(interactionOrTimeoutDetected_);

  React.useEffect(() => {
    if (interactionOrTimeoutDetected) {
      return;
    }

    const handle =
      timeout != null
        ? setTimeout(() => {
            interactionOrTimeoutDetected_ = true;
            setInteractionOrTimeoutDetected(true);
          }, timeout)
        : null;

    const options = hasPassiveEventsSupport()
      ? { passive: false, once: true }
      : { once: true };

    const handleEvent = () => {
      interactionOrTimeoutDetected_ = true;
      setInteractionOrTimeoutDetected(true);
      clearEvents();
    };

    const clearEvents = () => {
      document.removeEventListener('mousedown', handleEvent, options);
      document.removeEventListener('mousemove', handleEvent, options);
      document.removeEventListener('touchstart', handleEvent, options);
      document.removeEventListener('scroll', handleEvent, options);
      document.removeEventListener('keydown', handleEvent, options);
      clearTimeout(handle);
    };

    document.addEventListener('mousedown', handleEvent, options);
    document.addEventListener('mousemove', handleEvent, options);
    document.addEventListener('touchstart', handleEvent, options);
    document.addEventListener('scroll', handleEvent, options);
    document.addEventListener('keydown', handleEvent, options);

    return () => {
      clearEvents();
    };
  }, [timeout, interactionOrTimeoutDetected]);

  return interactionOrTimeoutDetected;
};
