/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ReaderFragment } from 'relay-runtime';
type Breadcrumbs_hierarchy$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Layout_pageWithData$ref: FragmentReference;
declare export opaque type Layout_pageWithData$fragmentType: Layout_pageWithData$ref;
export type Layout_pageWithData = {|
  +hierarchy: $ReadOnlyArray<?{|
    +$fragmentRefs: Breadcrumbs_hierarchy$ref
  |}>,
  +page: {|
    +template: ?string,
    +canonical_url: ?({|
      +__typename: "CMS_ExternalLink",
      +url: string,
    |} | {|
      +__typename: "CMSPage",
      +_meta: {|
        +uid: ?string,
        +lang: string,
      |},
    |} | {|
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      +__typename: "%other"
    |}),
    +meta_robots: ?string,
    +show_phone: ?boolean,
    +_meta: {|
      +id: string,
      +lang: string,
      +alternateLanguages: $ReadOnlyArray<{|
        +uid: ?string,
        +lang: string,
        +id: string,
      |}>,
    |},
  |},
  +$refType: Layout_pageWithData$ref,
|};
export type Layout_pageWithData$data = Layout_pageWithData;
export type Layout_pageWithData$key = {
  +$data?: Layout_pageWithData$data,
  +$fragmentRefs: Layout_pageWithData$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Layout_pageWithData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CMSPage",
      "kind": "LinkedField",
      "name": "hierarchy",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Breadcrumbs_hierarchy"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CMSPage",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "template",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "canonical_url",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "type": "CMS_ExternalLink",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CMSMeta",
                  "kind": "LinkedField",
                  "name": "_meta",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "CMSPage",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "meta_robots",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "show_phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CMSMeta",
          "kind": "LinkedField",
          "name": "_meta",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CMSRelatedDocument",
              "kind": "LinkedField",
              "name": "alternateLanguages",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CMSPageWithData",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '193803c9efd8f65248aeaef70d5381c9';

export default node;
