// @flow

import * as React from 'react';
import { useSystem } from 'react-system';
import { LogoBlack as IconLogoBlack } from '../../icons/logo-black.js';
import { Close as IconClose } from '../../icons/close.js';
import { Dialog } from '../../controls/modals';

type Props = {|
  open: boolean,
  onClose: () => void,
  children: React.Node,
  // Lightweight dialog for the main page, and MUI dialog for others, because it better handles scroll, etc.
  // TODO: write better handlers to replace MUI dialog if it's needed
  DialogComponent?: React.AbstractComponent<any>,
|};

export const LoginDialog = (props: Props): React.Node => {
  const { open, onClose, children, DialogComponent = Dialog } = props;
  const { media } = useSystem();

  return (
    <DialogComponent open={open} onClose={onClose} css={{ maxHeight: '90vh' }}>
      <div
        // prevent closing map popup
        data-invisible-for-click-outside="true"
        css={[
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'white',
            borderRadius: 4,
            maxHeight: '90vh',
            overflowX: 'auto',
            boxShadow:
              '0px 8px 10px -5px rgba(65,65,139, 0.08), 0px 16px 24px 2px rgba(65,65,139, 0.06), 0px 6px 30px 5px rgba(65,65,139, 0.04)',
            padding: '20px 40px 20px 40px',
            position: 'relative',
          },
          media({ width: ['100%', 456], height: ['100vh', 'auto'] }),
        ]}
      >
        <IconLogoBlack css={[media({ width: 160, height: 40 })]} />
        <button
          css={{
            position: 'absolute',
            right: 8,
            top: 6,

            appearance: 'none',
            WebkitTapHighlightColor: 'transparent',
            backgroundColor: 'transparent',
            border: 'none',
            padding: 12,
            opacity: 0.6,
            cursor: 'pointer',
            borderRadius: '100%',
            outline: 'none',
            '&:focus': {
              backgroundColor: 'rgba(0,0,0,0.2)',
            },
          }}
          onClick={onClose}
        >
          <IconClose size={24} />
        </button>
        {children}
      </div>
    </DialogComponent>
  );
};
