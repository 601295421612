/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserAssetsThirdPillarAOrganisationType = "bank" | "insurance_company";
export type UserAssetsThirdPillarBOrganisationType = "bank" | "insurance_company";
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UserMatrimonialRegime = "contribution_to_jointly_acquired_property" | "joint_estates" | "separate_estates";
export type UserProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
export type UpdateUserInput = {|
  id: string,
  firstName?: ?string,
  lastName?: ?string,
  title?: ?UserTitle,
  language?: ?UserLanguage,
  gender?: ?UserGender,
  dateOfBirth?: ?string,
  placeOfOrigin?: ?string,
  nationalityId?: ?string,
  residenceCountryCode?: ?string,
  residenceStateId?: ?string,
  identificationDocumentTypeId?: ?string,
  identificationDocumentNumber?: ?string,
  identificationDocumentIssueDate?: ?string,
  identificationDocumentExpiryDate?: ?string,
  numberOfDependantChildren?: ?number,
  numberOfBuyers?: ?number,
  maritalStatus?: ?UserMaritalStatus,
  professionalSituation?: ?UserProfessionalSituation,
  matrimonialRegime?: ?UserMatrimonialRegime,
  weddingDate?: ?string,
  divorceDate?: ?string,
  maritalPartnerId?: ?string,
  secondPillarOrganisationId?: ?string,
  thirdPillarAOrganisationId?: ?string,
  thirdPillarAAnnualPremiumOrganizationId?: ?string,
  thirdPillarBOrganisationId?: ?string,
  updatesNewLeadAssignedSms?: ?boolean,
  updatesIntroductionToLeadContacts?: ?boolean,
  updatesDailyTasks?: ?boolean,
  updatesPropertyRevaluation?: ?boolean,
  homeAddress?: ?AddressInput,
  workPermitId?: ?string,
  employerId?: ?string,
  employer?: ?string,
  jobTitle?: ?string,
  occupationId?: ?string,
  employmentRate?: ?number,
  employmentCommencementDate?: ?string,
  professionalAddress?: ?AddressInput,
  annualIncomeSalary?: ?number,
  annualIncomeSalary13?: ?boolean,
  annualIncomeSalary14?: ?boolean,
  annualIncomeBonus?: ?number,
  annualIncomeAdditionalExist?: ?boolean,
  annualIncomeChildSupport?: ?number,
  annualIncomeFamilyAllowances?: ?number,
  annualIncomeRent?: ?number,
  annualIncomeOther?: ?number,
  assetsSavings?: ?number,
  assetsSecurities?: ?number,
  assetsSavingsDonation?: ?number,
  assetsSecondPillarExist?: ?boolean,
  assetsSecondPillar?: ?number,
  assetsSecondPillarOrganisation?: ?string,
  assetsThirdPillarExist?: ?boolean,
  assetsThirdPillarAExist?: ?boolean,
  assetsThirdPillarA?: ?number,
  assetsThirdPillarALifeInsurance?: ?boolean,
  assetsThirdPillarAOrganisation?: ?string,
  assetsThirdPillarAAnnualPremiumOrganization?: ?string,
  assetsThirdPillarAOrganisationType?: ?UserAssetsThirdPillarAOrganisationType,
  assetsThirdPillarAAnnualPremium?: ?number,
  assetsThirdPillarACash?: ?number,
  assetsThirdPillarBExist?: ?boolean,
  assetsThirdPillarB?: ?number,
  assetsThirdPillarBLifeInsurance?: ?boolean,
  assetsThirdPillarBOrganisation?: ?string,
  assetsThirdPillarBOrganisationType?: ?UserAssetsThirdPillarBOrganisationType,
  assetsThirdPillarBAnnualPremium?: ?number,
  debtMortgageBalance?: ?number,
  debtLeasingBalance?: ?number,
  debtOtherCreditsBalance?: ?number,
  annualCostsLeasing?: ?number,
  annualCostsOtherCredits?: ?number,
  annualCostsChildSupport?: ?number,
  occupationText?: ?string,
  annualLeasingsExist?: ?boolean,
  annualLeasings?: ?$ReadOnlyArray<?UserAnnualLeasingInput>,
  annualCreditsExist?: ?boolean,
  annualCredits?: ?$ReadOnlyArray<?UserAnnualCreditInput>,
  annualOtherExist?: ?boolean,
  annualOtherPayments?: ?$ReadOnlyArray<?number>,
  propertiesExist?: ?boolean,
  primaryPhoneNumber?: ?string,
  primaryEmail?: ?string,
  hasChildren?: ?boolean,
  isAdmin?: ?boolean,
  isBroker?: ?boolean,
  membershipType?: ?string,
  primaryImageId?: ?string,
  hasAcceptedTerms?: ?boolean,
  brokerId?: ?string,
  accountManagerId?: ?string,
  mortgageBrokerId?: ?string,
  updatesNewsletter?: ?boolean,
  emailSignature?: ?string,
  emailSignatureHTML?: ?string,
  referrer?: ?string,
  modules?: ?$ReadOnlyArray<string>,
  canEditSettings?: ?boolean,
  canUserLoginAs?: ?boolean,
  autoAssignLeads?: ?boolean,
  hasFreeCrmAccess?: ?boolean,
  hasUsedTrialButton?: ?boolean,
  brokerCatchmentAreas?: ?$ReadOnlyArray<string>,
  lastBonus?: ?number,
  donation?: ?number,
  yearlyRevenuesAtRetirement?: ?number,
  brandingLogo?: ?string,
  brandingName?: ?string,
  brandingWebsite?: ?string,
  brandingColor?: ?string,
  clientMutationId?: ?string,
|};
export type AddressInput = {|
  route?: ?string,
  streetNumber?: ?string,
  postcode?: ?string,
  state?: ?string,
  locality?: ?string,
  countryCode?: ?string,
  lat?: ?number,
  lng?: ?number,
|};
export type UserAnnualLeasingInput = {|
  annualPayment?: ?number,
  remainingBalance?: ?number,
  endDate?: ?string,
|};
export type UserAnnualCreditInput = {|
  annualPayment?: ?number,
  remainingBalance?: ?number,
  endDate?: ?string,
  purpose?: ?string,
|};
export type LayoutUpdateUserLanguageMutationVariables = {|
  input: UpdateUserInput
|};
export type LayoutUpdateUserLanguageMutationResponse = {|
  +updateUser: ?{|
    +user: ?{|
      +id: string,
      +language: ?UserLanguage,
    |}
  |}
|};
export type LayoutUpdateUserLanguageMutation = {|
  variables: LayoutUpdateUserLanguageMutationVariables,
  response: LayoutUpdateUserLanguageMutationResponse,
|};
*/


/*
mutation LayoutUpdateUserLanguageMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    user {
      id
      language
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutUpdateUserLanguageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LayoutUpdateUserLanguageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fa229189469351b866504385a57e045",
    "id": null,
    "metadata": {},
    "name": "LayoutUpdateUserLanguageMutation",
    "operationKind": "mutation",
    "text": "mutation LayoutUpdateUserLanguageMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n      language\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac44564547484f69852d0e26dfe3fa7e';

export default node;
