// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useFocusLock, focusGuardProps } from '../hooks/focus-lock';
import { useSrollLock } from '../hooks/scroll-lock';

const BaseModal = props => {
  const focusLockArea = React.useRef(null);
  useFocusLock(focusLockArea);
  useSrollLock();

  return (
    <>
      <div
        css={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 10,
        }}
        onClick={props.onClose}
      />
      <div
        css={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: props.alignItems,
          justifyContent: props.justifyContent,
          pointerEvents: 'none',
          zIndex: 11,
        }}
      >
        <div
          ref={focusLockArea}
          css={{
            width: 'fit-content',
            pointerEvents: 'all',
            display: 'flex',
          }}
        >
          {/* Guards is needed to not scroll page content to focused element */}
          <div {...focusGuardProps} />
          {props.children}
          <div {...focusGuardProps} />
        </div>
      </div>
    </>
  );
};

const BaseModalPortal = ({ open, ...otherProps }) => {
  if (!open) {
    return null;
  }

  if (typeof document !== 'undefined' && document.body) {
    return ReactDOM.createPortal(<BaseModal {...otherProps} />, document.body);
  } else {
    return <BaseModal {...otherProps} />;
  }
};

export const Dialog = (props: {|
  open: boolean,
  onClose: () => void,
  children: React.Node,
  css?: any,
|}): React.Node => {
  return (
    <BaseModalPortal {...props} alignItems="center" justifyContent="center" />
  );
};

export const Drawer = (props: {|
  open: boolean,
  onClose: () => void,
  children: React.Node,
|}): React.Node => {
  return (
    <BaseModalPortal
      {...props}
      alignItems="stretch"
      justifyContent="flex-start"
    />
  );
};
