// @flow

import * as React from 'react';

// Super simplified focus lock for usage in layout and pages where size matters
// Not compliant with anything

const focusableSelectors = [
  'button:enabled:not([readonly])',
  'select:enabled:not([readonly])',
  'textarea:enabled:not([readonly])',
  'input:enabled:not([readonly])',

  'a[href]',
  'area[href]',

  'iframe',
  'object',
  'embed',

  '[tabindex]',
  '[contenteditable]',
  '[autofocus]',
];

export const focusGuardProps = {
  'data-focus-guard': true,
  tabIndex: 0,
};

const isGuard = elt => elt.dataset.focusGuard != null;

const isFocusInsideArea = areaElt => {
  if (document.activeElement == null) {
    return false;
  }

  if (isGuard(document.activeElement)) {
    return false;
  }

  return areaElt.contains(document.activeElement);
};

export const useFocusLock = (focusAreaRef: {|
  current: React.ElementRef<'div'> | null,
|}) => {
  React.useEffect(() => {
    const handleFocusIn = (evt: FocusEvent | null) => {
      if (focusAreaRef.current != null) {
        if (!isFocusInsideArea(focusAreaRef.current)) {
          setTimeout(() => {
            if (focusAreaRef.current != null) {
              if (!isFocusInsideArea(focusAreaRef.current)) {
                // focus first
                const elts = Array.from(
                  // $FlowFixMe[incompatible-call]
                  focusAreaRef.current.querySelectorAll(focusableSelectors),
                ).filter(elt => !isGuard(elt));

                if (elts.length > 0) {
                  elts[0].focus();
                }
              }
            }
          });
        }
      }

      if (evt != null) {
        // evt.preventDefault();
      }
    };

    handleFocusIn(null);

    document.addEventListener('focusin', handleFocusIn, true);
    return () => {
      document.removeEventListener('focusin', handleFocusIn, true);
    };
  }, [focusAreaRef]);
};
