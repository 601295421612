/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LayoutMeQueryVariables = {||};
export type LayoutMeQueryResponse = {|
  +me: ?{|
    +id: string,
    +firstName: ?string,
    +isAdmin: ?boolean,
    +isBroker: ?boolean,
  |}
|};
export type LayoutMeQuery = {|
  variables: LayoutMeQueryVariables,
  response: LayoutMeQueryResponse,
|};
*/


/*
query LayoutMeQuery {
  me {
    id
    firstName
    isAdmin
    isBroker
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isBroker",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutMeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LayoutMeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9ac1dc50d9fb4bbe59fdfba7bd79d2b8",
    "id": null,
    "metadata": {},
    "name": "LayoutMeQuery",
    "operationKind": "query",
    "text": "query LayoutMeQuery {\n  me {\n    id\n    firstName\n    isAdmin\n    isBroker\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a49252fe467af6af9571658c1350cf76';

export default node;
