// @flow

import * as React from 'react';
import NextLink from 'next/link';
import { toAsUrl, type Href } from '../../utils/rewrites';
import { useUserData } from '../../hooks/user-data';
export type { Href } from '../../utils/rewrites';
import { useRouter } from '../router';

import { type Language, useLocale } from '../locale';

type BaseProps = {|
  href: Href,
  prefetch?: boolean,
  shallow?: boolean,
  replace?: boolean,
  as?: string,
  scroll?: boolean,
  language?: Language,
  // until this https://github.com/facebook/react/issues/15719 is solved we will use
  // external router to avoid break memo in nextjs app
  // router?: Router,
  country?: ?('ch' | 'fr' | 'de' | 'es' | 'gb' | 'it'),
|};
type Props = {|
  ...BaseProps,
  children:
    | (({|
        href: string,
        onClick: (SyntheticMouseEvent<>) => void,
        ref:
          | { current: null | HTMLElement, ... }
          | ((null | HTMLElement) => mixed),
      |}) => React.Node)
    | React.Node,
|};

// https://github.com/zeit/next.js/issues/7915
const CallHref = React.forwardRef<
  {|
    href?: string,
    onClick?: (SyntheticMouseEvent<>) => void,
    children: ({|
      href: string,
      onClick: (SyntheticMouseEvent<>) => void,
      ref:
        | { current: null | HTMLElement, ... }
        | ((null | HTMLElement) => mixed),
    |}) => React.Node,
  |},
  HTMLElement | null,
>(({ href, onClick, children }, ref) => {
  if (href == null || onClick == null) {
    return null;
  }
  return children({ href, onClick, ref });
});

const LinkInternal = props => {
  const { language: localeLanguage } = useLocale();
  // the only use case of this is to allow links on other languages
  const language = props.language ?? localeLanguage;
  const userData = useUserData();
  const { country } = props;

  const linkRoutes =
    country != null ? userData.countryRoutes[country] : userData.routes;

  const res = toAsUrl(linkRoutes, language, props.href, props.router);

  let href = res.href;

  // -------------------------------------------------------------
  //       -==<<<custom agg support part>>>==---------------------
  const ANY_ORIGIN = 'http://base';
  const asUrl = new URL(res.as, ANY_ORIGIN);
  let asUrlPathname = asUrl.pathname;
  if (asUrlPathname.length > 1 && asUrlPathname.endsWith('/')) {
    asUrlPathname = asUrlPathname.slice(0, -1);
  }

  // aggregator_custom_supported_paths_ch is rendered by aggregator-custom app,
  // add origin to href to use external routing
  // asUrl.origin === ANY_ORIGIN - means that res.as is relative path
  if (
    props?.href?.pathname === '/place-prices' ||
    (asUrl.origin === ANY_ORIGIN &&
      userData.aggregator_custom_supported_paths_ch.some(ap => {
        const [apCountry, apPathname] = ap.split(':');

        return (
          userData.countryDomain === apCountry && asUrlPathname === apPathname
        );
      }))
  ) {
    href = `${userData.origin}${res.as}`;
  }
  //----------------------------------------------------------------

  return (
    <NextLink
      href={href}
      as={props.as ?? res.as}
      passHref={true}
      prefetch={props.prefetch ?? false}
      shallow={props.shallow}
      scroll={props.scroll}
      replace={props.replace}
    >
      {typeof props.children === 'function' ? (
        <CallHref>{props.children}</CallHref>
      ) : (
        props.children
      )}
    </NextLink>
  );
};

export const Link = (props: Props): React.Node => {
  const router = useRouter();
  return <LinkInternal {...props} router={router} />;
};

export const useAsUrl = (props: BaseProps): string => {
  const router = useRouter();
  const { language: localeLanguage } = useLocale();
  // the only use case of this is to allow links on other languages
  const language = props.language ?? localeLanguage;
  const userData = useUserData();
  const res = toAsUrl(userData.routes, language, props.href, router);

  return res.as;
};
