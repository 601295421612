// @flow

import * as React from 'react';

// Tiny simple documentElement scroll lock
// Doesn't work in stacked components, can break existing style elements on html element
// the only good thing is its size

export const useSrollLock = (): void => {
  React.useEffect(() => {
    const doc = document.documentElement;
    if (doc == null) {
      return;
    }

    const hasScrollbar = doc.clientHeight < doc.scrollHeight;
    const scrollTop = doc.scrollTop;

    let cssText = 'position:fixed; width: 100%;';

    if (hasScrollbar) {
      cssText += 'overflow-y: scroll;';
      cssText += `top: ${-scrollTop}px;`;
    }

    // Without raf I see flickering on safari
    requestAnimationFrame(() => {
      doc.style.cssText = cssText;
    });

    return () => {
      // Without raf I see flickering on every browser
      requestAnimationFrame(() => {
        doc.style.cssText = '';
        doc.scrollTo(0, scrollTop);
      });
    };
  }, []);
};
