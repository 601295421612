/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Layout_root$ref: FragmentReference;
declare export opaque type Layout_root$fragmentType: Layout_root$ref;
export type Layout_root = {|
  +cms: {|
    +navigation: ?{|
      +nav_logo_link: ?{|
        +template?: ?string,
        +_meta?: {|
          +uid: ?string,
          +lang: string,
        |},
      |},
      +phone_number: ?string,
      +body: ?$ReadOnlyArray<{|
        +primary?: ?{|
          +label: ?string,
          +pathname: ?string,
          +nav_link: ?{|
            +template?: ?string,
            +_meta?: {|
              +id: string,
              +uid: ?string,
              +lang: string,
            |},
          |},
        |},
        +fields?: ?$ReadOnlyArray<{|
          +label: ?string,
          +pathname: ?string,
          +sub_nav_link: ?{|
            +template?: ?string,
            +_meta?: {|
              +uid: ?string,
              +lang: string,
            |},
          |},
        |}>,
      |}>,
    |},
    +footerNavigation: ?{|
      +phone_number: ?string,
      +body: ?$ReadOnlyArray<{|
        +primary?: ?{|
          +label: ?string
        |},
        +fields?: ?$ReadOnlyArray<{|
          +label: ?string,
          +pathname: ?string,
          +sub_nav_link: ?{|
            +template?: ?string,
            +_meta?: {|
              +uid: ?string,
              +lang: string,
            |},
            +url?: string,
          |},
        |}>,
      |}>,
    |},
  |},
  +$refType: Layout_root$ref,
|};
export type Layout_root$data = Layout_root;
export type Layout_root$key = {
  +$data?: Layout_root$data,
  +$fragmentRefs: Layout_root$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "template",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CMSMeta",
      "kind": "LinkedField",
      "name": "_meta",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "CMSPage",
  "abstractKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pathname",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Layout_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CMS",
      "kind": "LinkedField",
      "name": "cms",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "uid",
              "value": "main"
            }
          ],
          "concreteType": "CMSNavigation",
          "kind": "LinkedField",
          "name": "navigation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "nav_logo_link",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "body",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CMSNavigationBodyNavigation_itemPrimary",
                      "kind": "LinkedField",
                      "name": "primary",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "nav_link",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CMSMeta",
                                  "kind": "LinkedField",
                                  "name": "_meta",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "id",
                                      "storageKey": null
                                    },
                                    (v2/*: any*/),
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "CMSPage",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CMSNavigationBodyNavigation_itemFields",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": true,
                      "selections": [
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "sub_nav_link",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "CMSNavigationBodyNavigation_item",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "footerNavigation",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "uid",
              "value": "footer"
            }
          ],
          "concreteType": "CMSNavigation",
          "kind": "LinkedField",
          "name": "navigation",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "body",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CMSNavigationBodyNavigation_itemPrimary",
                      "kind": "LinkedField",
                      "name": "primary",
                      "plural": false,
                      "selections": [
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CMSNavigationBodyNavigation_itemFields",
                      "kind": "LinkedField",
                      "name": "fields",
                      "plural": true,
                      "selections": [
                        (v7/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "sub_nav_link",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                }
                              ],
                              "type": "CMS_ExternalLink",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "CMSNavigationBodyNavigation_item",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9eb9b0cca0b5615bfe3414e2035fce5';

export default node;
