// @flow

/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import dynamic from 'next/dynamic';
import { Dialog } from '../controls/modals';
import { useAnalytics } from '../controls/analytics/index.js';
import { emitter } from './relay/createRelayEnvironment.js';

const RuntimeServerErrorDialogContent = dynamic(() =>
  import('./runtime-server-error-dialog-mui').then(
    m => m.RuntimeServerErrorDialogContent,
  ),
);

export const RuntimeServerErrorDialog = (): React.Node => {
  const { track } = useAnalytics();

  const [errorHappened, setErrorHappened] = React.useState(false);
  React.useEffect(() => {
    const handler = message => {
      setErrorHappened(true);
      track('RA.Web.RuntimeServerError', {
        message,
      });
    };
    return emitter.on('runtime-server-error', handler);
  }, []);
  return (
    <Dialog open={errorHappened} onClose={() => setErrorHappened(false)}>
      <div
        css={{
          maxWidth: 600,
          backgroundColor: 'white',
          padding: 0,
          borderRadius: 4,
          boxShadow:
            '0px 8px 10px -5px rgba(65,65,139, 0.08), 0px 16px 24px 2px rgba(65,65,139, 0.06), 0px 6px 30px 5px rgba(65,65,139, 0.04)',
        }}
      >
        <RuntimeServerErrorDialogContent
          onClose={() => setErrorHappened(false)}
        />
      </div>
    </Dialog>
  );
};
