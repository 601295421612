// @flow

/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import { useTheme } from '../theme';

const makeQuery = value => {
  const convertedValue =
    typeof value === 'number' ? `${Math.ceil(value / 16)}em` : value;
  return `screen and (min-width: ${convertedValue})`;
};

// This is client only optimisation, if we already know that device is mobile
// and there were no ssr, let's set it initially to right value
let isMobile_ = null;
// The first issue with useResponsive that it returns first value before size is detected,
// but in my case I need to explicitly know that size is not detected and have same behaviour on SSR and first render.
// The other issue with useResponsive that at the moment of useEffect
// it's still have no right value (it uses setState internally which is not applied at
// parent useEffect moment) but I need to somehow detect that detection occurred to set
// right value. Haven't found a better way to do this.
export const useIsMobile = (): null | boolean => {
  const [mobile, setMobile] = React.useState(isMobile_);
  const theme = useTheme();

  React.useEffect(() => {
    const handleResize = () => {
      if (
        window.matchMedia(makeQuery(Number.parseFloat(theme.breakpoints[0])))
          .matches
      ) {
        setMobile(false);
        isMobile_ = false;
      } else {
        setMobile(true);
        isMobile_ = true;
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return mobile;
};
