// @flow

import * as React from 'react';
import {
  graphql,
  useMutation,
  useFragment,
  useLazyLoadQuery,
} from 'react-relay';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Flex, Box, useSystem } from 'react-system';
import { logoutUser } from '@realadvisor/auth-client';
import { ExpandMore } from '../icons/expand-more.js';
import { Menu } from '../icons/menu.js';
import { LogoBlack } from '../icons/logo-black.js';
import { LogoWhite } from '../icons/logo-white.js';
import { Phone } from '../icons/phone.js';
import { Facebook } from '../icons/facebook.js';
import { Twitter } from '../icons/twitter.js';
import { Linkedin } from '../icons/linkedin.js';
import { Instagram } from '../icons/instagram.js';
import { Youtube } from '../icons/youtube.js';
import { useTheme } from '../theme';
import { languageHosts, languageCountries, normalLanguages } from '../language';
import { type Language, useLocale } from '../hooks/locale.js';
import * as cookie from '../utils/cookie';
import { useRouter } from '../controls/router';
import { Drawer } from '../controls/modals';
import { RuntimeServerErrorDialog } from '../controls/runtime-server-error-dialog.js';
import { Link, type Href } from '../controls/link';
import { Center } from '../controls/Center';
import { LazyHydrate } from '../controls/lazy';
import { useUserData } from '../hooks/user-data.js';
import { useIsMobile } from '../hooks/mobile';
import type { StrictObjHref } from '../utils/rewrites/index.js';
import { JsonLD } from '../shared/prismic/Breadcrumbs';
import { StructuredData } from '../controls/structured-data';
import type { CountryDomain } from '../server/UserData.js';
import { LoginDialog } from './login/login-dialog';
import type { Layout_root$key } from './__generated__/Layout_root.graphql.js';
import type { Layout_pageWithData$key } from './__generated__/Layout_pageWithData.graphql.js';
import type { LayoutMeQuery } from './__generated__/LayoutMeQuery.graphql';
import type { LayoutUpdateUserLanguageMutation } from './__generated__/LayoutUpdateUserLanguageMutation.graphql.js';

const GoToCrm = dynamic(
  () =>
    import('../controls/go-to-crm.js').then(async m => {
      return m.GoToCrm;
    }),
  { ssr: false },
);

const LoginContextual = dynamic(
  () => import('./login').then(m => m.LoginContextual),
  { ssr: false, loading: () => <div css={{ minHeight: 431 }} /> },
);

// Cache me data to avoid blinking
let lastMeData_ = null;

const useMe = () => {
  const data = useLazyLoadQuery<LayoutMeQuery>(
    graphql`
      query LayoutMeQuery {
        me {
          id
          firstName
          isAdmin
          isBroker
        }
      }
    `,
    {},
  );

  lastMeData_ = data;
  return data.me;
};

const useLogout = () => {
  const [logoutInProgress, setLogoutInProgress] = React.useState(false);
  const logoutWithReload = () => {
    setLogoutInProgress(true);
    logoutUser();
  };
  return [logoutWithReload, logoutInProgress];
};

type Styles = {|
  footer?: any,
|};

type Props = {|
  root: ?Layout_root$key,
  pageWithData?: Layout_pageWithData$key | null,
  headerStyle:
    | 'normal'
    | 'listings-page'
    | 'property-prices-page'
    | 'transparent-dark'
    | 'transparent-light',
  children: React.Node,
  headerContent?: React.Node,
  showFooter?: boolean,
  hideLocaleSwitcher?: boolean,
  hideMainNavigation?: boolean,
  hideMobileNavigation?: boolean,
  showPhoneNumber?: boolean,
  // Allows to override query params in some languages
  localeSwitcherQuery?: ?{|
    en: any,
    fr: any,
    de: any,
    it: any,
    es: any,
  |},
  logoHref?: ?Href,
  styles?: Styles,
  customCountrySwitcherHref?: StrictObjHref,
|};

type HeaderLinkProps = {|
  href: Href,
  children: React.Node,
  rel?: string,
  className?: string,
  css?: any,
  isCurrent?: boolean,
|};

const getPrismicHref = (link, pathname) => {
  if (link && Object.keys(link).length > 0) {
    const uid = link._meta?.uid ?? null;
    const template = link.template ?? '';
    return {
      pathname: `/${template}`,
      query: {
        uid,
      },
    };
  } else {
    return pathname ?? '';
  }
};

const HeaderLink = React.forwardRef<HeaderLinkProps, HTMLElement>(
  ({ href, children, rel, isCurrent }, ref) => {
    const { text } = useTheme();

    return (
      <Link href={href}>
        <a
          ref={ref}
          css={{
            ...text.subtitle1,
            ...text.link,
            ...(isCurrent === true
              ? { boxShadow: 'inset 0 -2px 0 -1px currentColor' }
              : {}),
            paddingLeft: 16,
            paddingRight: 16,
            lineHeight: '40px',
          }}
          rel={rel}
        >
          {children}
        </a>
      </Link>
    );
  },
);

const HeaderExpand = ({ title, children, ml = undefined }) => {
  const { text, shadows, shape } = useTheme();

  return (
    <Box
      py={2}
      ml={ml}
      flexShrink={0}
      css={{ position: 'relative', cursor: 'pointer' }}
    >
      <span
        css={[
          text.subtitle1,
          {
            'div:hover > &': {
              opacity: 0.8,
            },
          },
        ]}
      >
        {title}
      </span>
      <ExpandMore
        css={[
          text.icon,
          {
            'div:hover > &': {
              opacity: 0.8,
            },
          },
        ]}
      />
      {/* we use LazyHydrate as links are most heavy elements, so dont render at client until user interactions */}
      <LazyHydrate>
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            display: 'none',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            'div:hover > &': {
              display: 'flex',
            },
          }}
        >
          <div
            css={{
              backgroundColor: 'white',
              paddingTop: 8,
              paddingBottom: 8,
              borderRadius: shape.borderRadius,
              boxShadow: shadows.elevation3,
            }}
          >
            {children}
          </div>
        </div>
      </LazyHydrate>
    </Box>
  );
};

const MENU_ITEM_STYLE = {
  position: 'relative',
  display: 'block',
  padding: '8px 16px',
  appearance: 'none',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.84)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
};

const MenuItem = ({ href = null, indent = false, ...props }) => {
  const style = indent ? { paddingLeft: 32 } : null;

  if (href != null) {
    return (
      <Link href={href}>
        <a style={style} css={MENU_ITEM_STYLE} {...props} />
      </Link>
    );
  } else {
    return <a style={style} css={MENU_ITEM_STYLE} {...props} />;
  }
};

const LocaleSwitcherLink = ({ href, language, children, ...props }) => {
  const [updateLanguage] =
    useMutation<LayoutUpdateUserLanguageMutation>(graphql`
      mutation LayoutUpdateUserLanguageMutation($input: UpdateUserInput!) {
        updateUser(input: $input) {
          user {
            id
            language
          }
        }
      }
    `);
  return (
    <Link href={href} language={language}>
      {({ href }) => (
        <a
          css={MENU_ITEM_STYLE}
          {...props}
          href={href}
          onClick={e => {
            e.preventDefault();

            // we need this cookie at server
            // if url does not contain any language we use cookie to detect if user language choice was english
            // also to prefer this over headers autodetect on a first page only
            const AFTER_5_YEARS = 5 * 365;
            cookie.set('language', language, { expires: AFTER_5_YEARS });

            // update user language on db
            const userId = lastMeData_?.me?.id;
            if (userId != null) {
              updateLanguage({
                variables: {
                  input: {
                    id: userId,
                    language,
                  },
                },
              });
            }

            window.location = href;
          }}
        >
          {children}
        </a>
      )}
    </Link>
  );
};

const LocaleSwitcher = ({
  alternateLanguages,
  template,
  localeSwitcherQuery,
  page,
}) => {
  const { t, language, languages } = useLocale();
  const { host: currentHost, protocol, activePrismicLocales } = useUserData();
  const router = useRouter();

  // !!! Do not type labels otherwise checks below will not work
  const shortLabels = {
    en: t('en'),
    fr: t('fr'),
    de: t('de'),
    it: t('it'),
    es: t('es'),
  };

  // this will fail if new language is added in Languages
  ((('': any): Language): $Keys<typeof shortLabels>);
  // this will fail if new language is removed from Languages
  ((('': any): $Keys<typeof shortLabels>): Language);
  // in order of fail add/remove locales if needed

  const longLabels = {
    en: 'English',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    es: 'Español',
  };

  // this will fail if new language is added in Languages
  ((('': any): Language): $Keys<typeof longLabels>);
  // this will fail if new language is removed from Languages
  ((('': any): $Keys<typeof longLabels>): Language);
  // in order of fail add/remove locales if needed

  const {
    pathname,
    query: { lang, ...query },
  } = router;

  let transformedTemplate = template;
  let transformedQuery = query;

  if (template === 'popular-searches') {
    transformedTemplate = 'listings';
    transformedQuery = {};
  }

  // Cross-over to other countries only for content pages
  const allowCrossOver = () => {
    const excludeTemplates = ['place-prices', 'sitemap'];

    if (excludeTemplates.includes(page?.template)) {
      return false;
    }

    const {
      query: { lang, uid, ...params },
    } = router;

    return Object.keys(params).length === 0;
  };

  const getHostByLanguage = (lang: ?string): string | null => {
    if (lang != null) {
      return languageHosts[lang];
    }

    return null;
  };

  const getCountryByLanguage = (lang: ?string): CountryDomain | null => {
    if (lang != null) {
      return languageCountries[lang];
    }

    return null;
  };

  // en-ch -> en-CH
  const capitalizeHrefLand = lang => {
    return lang.replace(/[-](.+)/g, s => s.toUpperCase());
  };

  const items = alternateLanguages
    ? alternateLanguages
        .map(({ uid, lang }) =>
          uid == null
            ? null
            : {
                lang,
                switchAllowed:
                  Object.values(activePrismicLocales).includes(lang),
                normalLanguage: normalLanguages[lang],
                hrefLang: capitalizeHrefLand(lang),
                // Languages from another hosts (e.g. france) are only included for static content
                isAlternate:
                  template === 'popular-searches'
                    ? false
                    : Object.values(activePrismicLocales).includes(lang)
                    ? true
                    : allowCrossOver(),
                host: getHostByLanguage(lang),
                country: getCountryByLanguage(lang),
                href: {
                  pathname: `/${transformedTemplate ?? ''}`,
                  query: {
                    ...transformedQuery,
                    uid,
                    ...(localeSwitcherQuery != null
                      ? localeSwitcherQuery[normalLanguages[lang]]
                      : {}),
                  },
                },
              },
        )
        .filter(Boolean)
        .concat([
          {
            lang: page?._meta.lang,
            host: getHostByLanguage(page?._meta.lang),
            country: getCountryByLanguage(page?._meta.lang),
            normalLanguage: language,
            switchAllowed: false,
            isAlternate: true,
            hrefLang: capitalizeHrefLand(lang),
            href: { pathname: router.pathname, query: router.query },
          },
        ])
    : languages.map(lng => ({
        normalLanguage: lng,
        switchAllowed: true,
        isAlternate: true,
        href: { pathname, query },
        hrefLang: capitalizeHrefLand(lng),
        host: currentHost,
        country: null,
      }));

  if (items.length === 1) {
    return null;
  }

  const switchAllowedItems = items.filter(i => i.switchAllowed === true);
  return (
    <>
      {items.map(
        ({ isAlternate, href, host, hrefLang, normalLanguage, country }) => {
          if (isAlternate !== true || host == null) {
            return null;
          }

          return (
            <Link
              key={hrefLang}
              href={href}
              language={normalLanguage}
              country={country}
            >
              {({ href: stringHref }) => {
                if (host == null) {
                  return null;
                }

                return (
                  <Head>
                    <link
                      rel="alternate"
                      href={`${protocol}://${host}${stringHref.split('?')[0]}`}
                      hrefLang={hrefLang}
                    />
                  </Head>
                );
              }}
            </Link>
          );
        },
      )}

      {switchAllowedItems.length >= 1 && (
        <HeaderExpand title={shortLabels[language]}>
          {switchAllowedItems.map(({ normalLanguage, href }) => (
            <LocaleSwitcherLink
              key={normalLanguage}
              selected={language === normalLanguage}
              language={normalLanguage}
              href={href}
            >
              {longLabels[normalLanguage]}
            </LocaleSwitcherLink>
          ))}
        </HeaderExpand>
      )}
    </>
  );
};

const FooterLink = ({ href, children }) => {
  const { pb } = useSystem();
  const { text } = useTheme();
  return (
    <Link href={href}>
      <a css={[text.body2, text.link, pb(3)]}>{children}</a>
    </Link>
  );
};

const FooterNavigation = ({ navigation }) => {
  const { pb } = useSystem();
  const { text } = useTheme();

  if (navigation == null) {
    return null;
  }

  return (navigation.body ?? []).map((nav, index) => {
    return (
      <Box key={index} width={[1, 1 / 5]} px={1} pb={3}>
        <FooterHeading>{nav.primary?.label}</FooterHeading>
        {/* iterate fields */}
        {(nav.fields ?? []).map((field, fldIndex) => {
          const url = field.sub_nav_link?.url;
          if (url != null) {
            return (
              <a key={fldIndex} href={url} css={[text.body2, text.link, pb(3)]}>
                {field.label}
              </a>
            );
          }
          return (
            <FooterLink
              key={fldIndex}
              href={getPrismicHref(field.sub_nav_link, field.pathname)}
            >
              {field.label}
            </FooterLink>
          );
        })}
      </Box>
    );
  });
};

const MainSecondLevel = ({ nav }) => {
  return (
    <HeaderExpand ml={3} title={nav.primary?.label}>
      {(nav.fields ?? []).map((field, index) => {
        return (
          <MenuItem
            key={index}
            href={getPrismicHref(field.sub_nav_link, field.pathname)}
          >
            {field.label ?? null}
          </MenuItem>
        );
      })}
    </HeaderExpand>
  );
};

const MainNavigation = ({ navigation, short, onMenuOpen, currentPageId }) => {
  const { media } = useSystem();
  const isMobile = useIsMobile();

  if (typeof window !== 'undefined' && isMobile == null) {
    // Hydration, temporary hack to not render heavyweight Link elements on mobile
    // aka Lazy Hydration (we show SSR content but hydrate only if desktop
    return (
      <div
        dangerouslySetInnerHTML={{ __html: '' }}
        suppressHydrationWarning={true}
      />
    );
  }

  if (isMobile === true) {
    return null;
  }

  const links = short ? navigation.body?.slice(0, 3) : navigation.body;

  return (
    <Flex
      ml="auto"
      mr={3}
      css={media({ display: ['none', 'none', 'flex'] })}
      // css={{ overflow: 'hidden' }}
      alignItems="center"
      height={'40px'}
      justifyContent="flex-end"
    >
      <Box width={'0px'} height={'40px'} />
      {links?.map((nav, index) => {
        if (nav.fields != null && nav.fields.length !== 0) {
          return <MainSecondLevel key={index} nav={nav} />;
        } else {
          const id = nav.primary?.nav_link?._meta?.id ?? '';
          return (
            <HeaderLink
              key={index}
              // css={{ lineHeight: '40px' }}
              isCurrent={id === currentPageId}
              href={getPrismicHref(
                nav.primary?.nav_link,
                nav.primary?.pathname,
              )}
            >
              {nav.primary?.label ?? null}
            </HeaderLink>
          );
        }
      })}
      {short && (
        <Box ml={2}>
          <IconButton color="inherit" onClick={onMenuOpen}>
            <Menu size={24} />
          </IconButton>
        </Box>
      )}
    </Flex>
  );
};

const MainLoginDesktop = () => {
  const { media } = useSystem();
  const isMobile = useIsMobile();

  return (
    <Box
      mr={2}
      css={media({
        display: ['none', 'block'],
        minWidth: '100px',
        textAlign: 'right',
      })}
    >
      {isMobile === false && (
        <React.Suspense fallback={null}>
          <MainLoginDesktopView />
        </React.Suspense>
      )}
    </Box>
  );
};

const MainLoginDesktopView = () => {
  const { mx } = useSystem();
  const { t } = useLocale();
  const router = useRouter();
  const { text } = useTheme();

  const [loginDialog, setLoginDialog] = React.useState(false);
  const [logout, logoutInProgress] = useLogout();

  const me = useMe();

  if (me == null) {
    return router.pathname.startsWith('/login') ? null : (
      <>
        <Box mr={-2}>
          <button
            css={[
              text.subtitle1,
              text.link,
              mx(3),
              {
                background: 'none',
                border: 'none',
                padding: 0,
                '&:focus': { outline: 'none' },
              },
            ]}
            onClick={() => setLoginDialog(true)}
          >
            {t('login')}
          </button>
        </Box>
        <Login open={loginDialog} onClose={() => setLoginDialog(false)} />
      </>
    );
  } else {
    const meHaveFirstName = me.firstName != null && me.firstName !== '';
    return (
      <HeaderExpand title={meHaveFirstName ? me.firstName : t('myAccount')}>
        {meHaveFirstName ? (
          <MenuItem
            href={{
              pathname: '/profile',
              query: {},
            }}
          >
            {t('myAccount')}
          </MenuItem>
        ) : null}
        <MenuItem disabled={logoutInProgress} onClick={logout}>
          {t('logout')}
        </MenuItem>
      </HeaderExpand>
    );
  }
};

const DrawerSecondLevel = ({ nav }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <MenuItem
        selected={open}
        onClick={event => {
          event.stopPropagation();
          setOpen(v => !v);
        }}
      >
        {nav.primary?.label ?? null}
        <ExpandMore
          size={24}
          css={{ position: 'absolute', top: 8, right: 16 }}
        />
      </MenuItem>
      {open && (
        <div css={{ padding: '8px 0' }}>
          {nav.fields?.map((field, index) => {
            return (
              <MenuItem
                key={index}
                indent={true}
                href={getPrismicHref(field.sub_nav_link, field?.pathname)}
              >
                {field.label ?? null}
              </MenuItem>
            );
          })}
        </div>
      )}
    </>
  );
};

const DrawerNavigation = ({ navigation }) => {
  return (navigation?.body ?? []).map((nav, index) => {
    if (nav.fields && nav.fields.length !== 0) {
      return <DrawerSecondLevel key={index} nav={nav} />;
    } else {
      return (
        <MenuItem
          key={index}
          href={getPrismicHref(nav.primary?.nav_link, nav.primary?.pathname)}
        >
          {nav.primary?.label ?? null}
        </MenuItem>
      );
    }
  });
};

const Divider = () => {
  return (
    <hr
      css={{
        border: 'none',
        height: 1,
        margin: 0,
        flexShrink: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      }}
    />
  );
};

const DrawerLogin = ({ logout, logoutInProgress }) => {
  const { t } = useLocale();
  const router = useRouter();
  const me = useMe();
  if (me == null) {
    return router.pathname.startsWith('/login') ? null : (
      <>
        <Divider />
        <MenuItem
          href={{
            pathname: '/login',
            query: {
              return: router.asPath,
            },
          }}
        >
          {t('login')}
        </MenuItem>
      </>
    );
  } else {
    return (
      <>
        <Divider />
        <MenuItem href="/profile">{t('myAccount')}</MenuItem>
        <MenuItem disabled={logoutInProgress} onClick={logout}>
          {t('logout')}
        </MenuItem>
      </>
    );
  }
};

export const MOBILE_HEADER_HEIGH = 56;
export const DESKTOP_HEADER_HEIGHT = 80;
export const HEADER_Z_INDEX = 10;

const FullWidth = ({ children }) => (
  <Flex px={3} width={1} alignItems="center">
    {children}
  </Flex>
);

const Login = ({ open, onClose }) => {
  const { t } = useLocale();
  const { text } = useTheme();
  const router = useRouter();
  return (
    <LoginDialog open={open} onClose={onClose}>
      <Box py={4} css={[{ textAlign: 'center' }, text.h6]}>
        {t('signInOrRegister')}
      </Box>
      <LoginContextual
        privacyAgreement={null}
        communicationOptIn={null}
        returnHref={router.asPath}
      />
    </LoginDialog>
  );
};

const IconButton = props => {
  return (
    <button
      css={{
        appearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 12,
        color: 'inherit',
        cursor: 'pointer',
        borderRadius: '100%',
        outline: 'none',
        '&:focus': {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

const Header = ({
  headerStyle,
  content,
  page,
  navigation,
  hideLocaleSwitcher,
  hideMainNavigation,
  hideMobileNavigation,
  showPhoneNumber,
  localeSwitcherQuery,
  logoHref,
}) => {
  const { media } = useSystem();
  const { colors, text } = useTheme();

  const [menuOpen, setMenuOpen] = React.useState(false);
  // keep mutation outside of drawer to not dispose callback with reload when drawer is closed
  const [logout, logoutInProgress] = useLogout();

  const Logo = headerStyle === 'transparent-dark' ? LogoWhite : LogoBlack;
  const mainStyle = {
    zIndex: HEADER_Z_INDEX,
    top: 0,
    left: 0,
    right: 0,
  };
  let themeStyle;

  switch (headerStyle) {
    case 'transparent-dark':
      themeStyle = {
        position: 'absolute',
        color: colors.white,
        // borderBottom: '1px solid hsla(0,0%,100%,.25)',
      };
      break;
    case 'transparent-light':
      themeStyle = {
        position: 'absolute',
        color: colors.highText,
        // borderBottom: '1px solid hsla(0,0%,100%,.25)',
      };
      break;
    case 'listings-page':
    case 'property-prices-page':
      themeStyle = media({
        position: 'fixed', // ['fixed', 'absolute'],
        color: colors.highText,
        backgroundColor: colors.white,
        boxShadow: ['0 0 3px rgba(0,0,0,0.1)', '0 3px 3px rgba(0,0,0,0.1)'],
      });
      break;
    default:
      themeStyle = {
        position: 'absolute',
        color: colors.highText,
        backgroundColor: colors.white,
      };
      break;
  }

  const { origin } = useUserData();

  const mediaHeight = [
    `${MOBILE_HEADER_HEIGH}px`,
    `${DESKTOP_HEADER_HEIGHT}px`,
  ];

  const CenterComponent =
    headerStyle === 'listings-page' || headerStyle === 'property-prices-page'
      ? FullWidth
      : Center;

  return (
    // Top relative DIV is needed to be able to respect to body padding-right
    // - changed at disableBodyScroll call // otherwise there is a width jump on
    // menu header at Gallery, and other scroll locking controls
    <Box
      height={headerStyle.includes('transparent') ? 0 : mediaHeight}
      css={{ position: 'relative' }}
    >
      {typeof window === 'undefined' ? (
        <StructuredData
          type="LocalBusiness"
          properties={{
            name: 'RealAdvisor',
            image: `${origin}/static/images/logo.png`,
            url: origin,
            telephone: '+41 22 775 05 05',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Avenue Louis-Casaï 86A',
              addressLocality: 'Cointrin',
              postalCode: '1216',
              addressCountry: 'CH',
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: 46.22667690000001,
              longitude: 6.106204099999999,
            },
            openingHoursSpecification: [
              {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                ],
                opens: '08:30',
                closes: '19:30',
              },
            ],
            sameAs: [
              'https://www.facebook.com/realadvisorcom',
              'https://twitter.com/realadvisor',
            ],
          }}
        />
      ) : null}

      <Flex
        height={mediaHeight}
        alignItems="center"
        css={[mainStyle, themeStyle]}
      >
        <CenterComponent css={{ display: 'flex', alignItems: 'center' }}>
          <Box flexShrink={0} mr={3}>
            <Link
              href={
                logoHref != null
                  ? logoHref
                  : getPrismicHref(navigation?.nav_logo_link, null)
              }
            >
              <a aria-label="home">
                <Logo css={[media({ width: [111, 160], height: [20, 40] })]} />
              </a>
            </Link>
          </Box>
          {showPhoneNumber === true && navigation?.phone_number != null && (
            <Box
              px={2}
              as="a"
              css={[
                media([text.strongCaption, text.subtitle2]),
                {
                  textDecoration: 'none',
                  color: 'inherit',
                  cursor: 'pointer !important',
                  display: 'flex',
                  ':hover': {
                    opacity: '0.85',
                  },
                },
              ]}
              href={`tel:${navigation.phone_number ?? ''}`}
            >
              <Phone size={20} />
              <span
                css={media({
                  marginLeft: 4,
                  display: ['none', 'block', 'block'],
                })}
              >
                {navigation.phone_number}
              </span>
            </Box>
          )}
          {content}

          {navigation != null && hideMainNavigation !== true && (
            <MainNavigation
              navigation={navigation}
              currentPageId={page?._meta.id}
              onMenuOpen={() => setMenuOpen(true)}
              short={headerStyle === 'listings-page'}
            />
          )}

          <Flex ml="auto" alignItems="center" flexShrink={0}>
            <MainLoginDesktop />

            {hideLocaleSwitcher !== true && (
              <LocaleSwitcher
                page={page}
                alternateLanguages={page?._meta.alternateLanguages}
                template={page?.template}
                localeSwitcherQuery={localeSwitcherQuery}
              />
            )}
          </Flex>

          {hideMobileNavigation !== true && (
            <>
              <Box
                flexShrink={0}
                ml={[1, 3]}
                css={media({
                  display: ['block', 'block', 'none'],
                })}
              >
                <IconButton color="inherit" onClick={() => setMenuOpen(true)}>
                  <Menu size={24} />
                </IconButton>
              </Box>

              <Drawer open={menuOpen} onClose={() => setMenuOpen(false)}>
                <div
                  css={{
                    width: 280,
                    backgroundColor: 'white',
                    padding: '8px 0',
                    boxShadow:
                      '0px 8px 10px -5px rgba(65,65,139, 0.08), 0px 16px 24px 2px rgba(65,65,139, 0.06), 0px 6px 30px 5px rgba(65,65,139, 0.04)',
                  }}
                  onClick={() => setMenuOpen(false)}
                >
                  <DrawerNavigation navigation={navigation} />
                  <div css={media({ display: ['block', 'none'] })}>
                    <React.Suspense fallback={null}>
                      <DrawerLogin
                        logout={logout}
                        logoutInProgress={logoutInProgress}
                      />
                    </React.Suspense>
                  </div>
                </div>
              </Drawer>
            </>
          )}
        </CenterComponent>
      </Flex>
    </Box>
  );
};

const FooterHeading = ({ children }) => {
  const { text } = useTheme();

  return (
    <Box pb={3} css={text.subtitle1}>
      {children}
    </Box>
  );
};

const Footer = ({ footerNavigation, styles, showPhoneNumber }) => {
  const { p, media } = useSystem();
  const { text, textColor, bgColor } = useTheme();
  const { countryCode, version } = useUserData();
  const facebookColor = '#3b5998';

  return (
    <LazyHydrate>
      <Flex
        py={4}
        mt={[2, 4]}
        flexWrap="wrap"
        css={[
          styles?.footer,
          textColor('highText'),
          bgColor('white'),
          { overflow: 'hidden' },
        ]}
      >
        <Center css={{ display: 'flex', flexWrap: 'wrap' }}>
          <FooterNavigation navigation={footerNavigation} />
          <Box width={[1, 1 / 5]} px={1} pb={3}>
            <Box pb={3}>
              <LogoBlack
                css={media({
                  display: ['inline-block', 'inline-block', 'block'],
                  width: 140,
                  height: 32,
                })}
              />
            </Box>
            {showPhoneNumber === true &&
              footerNavigation?.phone_number != null && (
                <a
                  css={[
                    text.subtitle2,
                    {
                      textDecoration: 'none',
                      cursor: 'pointer',
                      color: 'inherit',
                      display: 'flex',
                      marginBottom: 18,
                      ':hover': {
                        opacity: '0.85',
                      },
                    },
                  ]}
                  href={`tel:${footerNavigation.phone_number}`}
                >
                  <Phone size={20} />
                  <span css={{ marginLeft: 4 }}>
                    {footerNavigation.phone_number}
                  </span>
                </a>
              )}
          </Box>
          <Box width={1} mt={3} pb={3} css={text.body2}>
            <Flex flexWrap="wrap" alignItems="center">
              <Box css={{ opacity: 0.5 }} flexGrow={1}>
                {`© RealAdvisor ${new Date().getFullYear()}. All Rights Reserved. v.${version}`}
              </Box>
              <Box flexGrow={1}>
                <Flex p={-2} justifyContent={['center', 'flex-end']}>
                  <a
                    href="https://ch.linkedin.com/company/realadvisor"
                    rel="noopener noreferrer"
                    target="_blank"
                    css={p(2)}
                  >
                    <Linkedin size={24} />
                  </a>

                  <a
                    href="https://www.facebook.com/realadvisorcom"
                    rel="noopener noreferrer"
                    target="_blank"
                    css={p(2)}
                  >
                    <Facebook size={24} fill={facebookColor} />
                  </a>

                  <a
                    href="https://twitter.com/realadvisor"
                    rel="noopener noreferrer"
                    target="_blank"
                    css={p(2)}
                  >
                    <Twitter size={24} />
                  </a>

                  {countryCode === 'CH' && (
                    <a
                      href="https://www.instagram.com/realadvisorcom"
                      rel="noopener noreferrer"
                      target="_blank"
                      css={p(2)}
                    >
                      <Instagram size={24} />
                    </a>
                  )}

                  {countryCode === 'FR' && (
                    <a
                      href="https://www.instagram.com/realadvisor.france/"
                      rel="noopener noreferrer"
                      target="_blank"
                      css={p(2)}
                    >
                      <Instagram size={24} />
                    </a>
                  )}

                  <a
                    href="https://www.youtube.com/channel/UCsEDTDlmxBpyxLXYhWT769Q"
                    rel="noopener noreferrer"
                    target="_blank"
                    css={p(2)}
                  >
                    <Youtube size={24} />
                  </a>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Center>
      </Flex>
    </LazyHydrate>
  );
};

const GotoCrmWrapper = () => {
  const me = useMe();
  return (me?.isAdmin === true || me?.isBroker === true) && <GoToCrm />;
};

export const Layout = (props: Props): React.Node => {
  const root = useFragment(
    graphql`
      fragment Layout_root on Query
      @argumentDefinitions(lang: { type: "String!" }) {
        cms {
          navigation(uid: "main", lang: $lang) {
            nav_logo_link {
              ... on CMSPage {
                template
                _meta {
                  uid
                  lang
                }
              }
            }
            phone_number
            body {
              ... on CMSNavigationBodyNavigation_item {
                primary {
                  label
                  pathname
                  nav_link {
                    ... on CMSPage {
                      template
                      _meta {
                        id
                        uid
                        lang
                      }
                    }
                  }
                }
                fields {
                  label
                  pathname
                  sub_nav_link {
                    ... on CMSPage {
                      template
                      _meta {
                        uid
                        lang
                      }
                    }
                  }
                }
              }
            }
          }
          footerNavigation: navigation(uid: "footer", lang: $lang) {
            phone_number
            body {
              ... on CMSNavigationBodyNavigation_item {
                primary {
                  label
                }
                fields {
                  label
                  pathname
                  sub_nav_link {
                    ... on CMSPage {
                      template
                      _meta {
                        uid
                        lang
                      }
                    }
                    ... on CMS_ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    props.root,
  );

  const pageWithData = useFragment(
    graphql`
      fragment Layout_pageWithData on CMSPageWithData {
        hierarchy {
          ...Breadcrumbs_hierarchy
        }
        page {
          template
          canonical_url {
            __typename
            ... on CMS_ExternalLink {
              url
            }
            ... on CMSPage {
              _meta {
                uid
                lang
              }
            }
          }
          meta_robots
          show_phone
          _meta {
            id
            lang
            alternateLanguages {
              uid
              lang
              id
            }
          }
        }
      }
    `,
    props.pageWithData,
  );
  const { showFooter = true } = props;
  const router = useRouter();
  const { query, pathname } = router;
  const isMobile = useIsMobile();

  const CANONICAL_SET_YOURSELF = ['/listings'];
  let canonicalHref = null;

  if (!CANONICAL_SET_YOURSELF.includes(router.pathname)) {
    const canonicalUrl = pageWithData?.page.canonical_url ?? null;

    if (canonicalUrl != null) {
      if (canonicalUrl.__typename === 'CMSPage') {
        const uid = canonicalUrl._meta.uid ?? null;

        if (uid != null) {
          canonicalHref = { pathname: uid };
        }
      } else if (canonicalUrl.__typename === 'CMS_ExternalLink') {
        const url = canonicalUrl.url ?? null;

        if (url != null) {
          canonicalHref = url;
        }
      }
    }

    if (canonicalHref == null) {
      canonicalHref = {
        pathname,
        query,
      };
    }
  }

  const metaRobots = pageWithData?.page.meta_robots ?? 'all';

  return (
    <Flex flexGrow={1} flexDirection="column">
      <RuntimeServerErrorDialog />

      {/* Call this on desktop only as it doesn't affect lighthouse score high */}
      {isMobile === false && (
        <React.Suspense fallback={null}>
          <GotoCrmWrapper />
        </React.Suspense>
      )}

      <Head>
        {canonicalHref != null && (
          <link
            rel="canonical"
            href={router.getUrl(canonicalHref).absolute.split('?')[0]}
          />
        )}
        <meta name="robots" content={metaRobots} />
      </Head>

      {typeof window === 'undefined' ? (
        <JsonLD hierarchy={pageWithData?.hierarchy.filter(Boolean)} />
      ) : null}

      <Header
        page={pageWithData?.page}
        navigation={root?.cms.navigation}
        headerStyle={props.headerStyle}
        content={props.headerContent}
        hideLocaleSwitcher={props.hideLocaleSwitcher}
        showPhoneNumber={pageWithData?.page.show_phone}
        hideMainNavigation={props.hideMainNavigation}
        hideMobileNavigation={props.hideMobileNavigation}
        localeSwitcherQuery={props.localeSwitcherQuery}
        logoHref={props.logoHref}
      />

      <Flex flexGrow={1} flexDirection={'column'}>
        {props.children}
      </Flex>

      {showFooter && (
        <Footer
          page={pageWithData?.page}
          footerNavigation={root?.cms.footerNavigation}
          showPhoneNumber={pageWithData?.page.show_phone}
          styles={props.styles}
          customCountrySwitcherHref={props.customCountrySwitcherHref}
        />
      )}
    </Flex>
  );
};
