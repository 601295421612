// @flow

import * as React from 'react';
import Head from 'next/head';
// $FlowFixMe[untyped-import]
import { htmlEscapeJsonString } from 'next/dist/server/htmlescape';
import { useLog } from '../hooks/log';

export type Properties = {
  [string]:
    | null
    | number
    | string
    | Properties
    | $ReadOnlyArray<null | number | string | Properties>,
  ...
};

type Props = {|
  id?: string,
  context?:
    | string
    | {
        [string]:
          | string
          | {|
              '@id': string,
              '@type': string,
            |},
        ...
      },
  type: string | $ReadOnlyArray<string>,
  properties: Properties,
  escapeHtml?: boolean,
|};

const DEFAULT_CONTEXT = 'http://schema.org';

export const StructuredData = ({
  id,
  context,
  type,
  properties,
  escapeHtml = true,
}: Props): React.Node => {
  const logger = useLog();

  const json = {
    ...properties,
    '@context': context ?? DEFAULT_CONTEXT,
    '@type': type,
  };

  if (id != null) {
    json['@id'] = id;
  }

  let html;

  try {
    html = escapeHtml
      ? htmlEscapeJsonString(JSON.stringify(json))
      : JSON.stringify(json);
  } catch (error) {
    logger.error(`Failed preparing structured data`, error);
    return null;
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Head>
  );
};
