// @flow

import * as React from 'react';
import { useAnyUserInteractionOrTimeout } from '../hooks/interactions';

// Lazy hydration component, based on this comment https://github.com/facebook/react/issues/10923#issuecomment-338715787
// Basically hack.

// Basically needs provider or some kind of cache to be in sync for rerenders
// But having it used in Layout can be skipped

export const LazyHydrate = (props: {|
  children: React.Node,
  timeout?: number,
|}): React.Node => {
  const interactionDetected = useAnyUserInteractionOrTimeout(
    props.timeout ?? null,
  );
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && !interactionDetected) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: '' }}
        suppressHydrationWarning={true}
      />
    );
  }

  return props.children;
};
