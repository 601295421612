// @flow

import * as React from 'react';
// $FlowFixMe[missing-export]
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { Box } from 'react-system';
import * as icons from '../icons';
import { useTheme } from '../theme';
import { useUserData } from '../hooks/user-data.js';
import { useLocale } from '../hooks/locale.js';
import { useRouter } from '../controls/router';
import { type Href } from '../utils/rewrites/rewrites.js';
import { Link } from './link';
import { A } from './A';
import { StructuredData } from './structured-data';

type Item = {|
  href: Href,
  onClick?: () => void,
  isHome?: boolean,
  label: string,
  rel?: 'nofollow',
|};

type Props = {|
  items: $ReadOnlyArray<Item>,
  className?: string,
|};

const BACKGROUND_COLOR = '255, 255, 255';

const Container = (props: {|
  children: React.Node,
  className?: string,
  css?: any,
|}) => {
  const ref = React.useRef();
  const [hasRightContent, setHasRightContent] = React.useState(false);
  const [hasLeftContent, setHasLeftContent] = React.useState(false);

  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.scrollLeft = 10000;
    }
  }, []);

  return (
    <div className={props.className} css={{ position: 'relative' }}>
      <div
        css={{
          overflowX: 'auto',
          overflowY: 'hidden',
          WebkitOverflowScrolling: 'touch',
          position: 'relative',
          scrollbarWidth: 0,
          scrollbarHeight: 0,
          '&::-webkit-scrollbar': {
            width: '0 !important',
            height: '0 !important',
          },
        }}
        ref={ref}
        onScroll={({ target }) => {
          const newHasRightContent =
            target.scrollLeft < target.scrollWidth - target.offsetWidth;
          if (newHasRightContent !== hasRightContent) {
            setHasRightContent(newHasRightContent);
          }

          const newHasLeftContent = target.scrollLeft > 0;
          if (newHasLeftContent !== hasLeftContent) {
            setHasLeftContent(newHasLeftContent);
          }
        }}
      >
        {props.children}
      </div>
      <div
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: 50,
          background: `linear-gradient(to right, rgba(${BACKGROUND_COLOR}, 1), rgba(${BACKGROUND_COLOR}, 0))`,
          pointerEvents: 'none',
          transition: 'opacity .3s',
          opacity: hasLeftContent ? '1' : '0',
        }}
      />
      <div
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          width: 50,
          background: `linear-gradient(to left, rgba(${BACKGROUND_COLOR}, 1), rgba(${BACKGROUND_COLOR}, 0))`,
          pointerEvents: 'none',
          transition: 'opacity .3s',
          opacity: hasRightContent ? '1' : '0',
        }}
      />
    </div>
  );
};

export const Breadcrumbs = ({ items, className }: Props): React.Node => {
  const { colors, text } = useTheme();

  const separator = (
    <icons.KeyboardArrowRight size={20} css={{ color: colors.grey900 }} />
  );

  const homeIcon = <icons.Home size={20} css={{ color: colors.grey900 }} />;

  const breadcrumbs = items.map((item, i, arr) => {
    if (item.isHome === true) {
      return (
        <Link href={item.href} key={i}>
          <A
            onClick={item.onClick}
            color={colors.primary.main}
            underline={false}
          >
            {homeIcon}
          </A>
        </Link>
      );
    }

    // render last element as text (#9651)
    if (i === arr.length - 1) {
      return (
        <Box key={i} css={{ color: colors.grey500 }}>
          {item.label}
        </Box>
      );
    }

    return (
      <Link key={i} href={item.href}>
        <A onClick={item.onClick} underline={false} rel={item.rel}>
          {item.label}
        </A>
      </Link>
    );
  });

  return (
    <Container className={className}>
      <MuiBreadcrumbs
        separator={separator}
        aria-label="breadcrumb"
        css={[
          text.body2,
          {
            whiteSpace: 'nowrap',
            '.MuiBreadcrumbs-li': {
              ':last-child': {
                // we want to be able to scroll a bit past last child (improves UX)
                paddingRight: 32,
              },
            },
            '.MuiBreadcrumbs-separator': { marginLeft: 4, marginRight: 4 },
          },
        ]}
      >
        {breadcrumbs}
      </MuiBreadcrumbs>
    </Container>
  );
};

type JsonLDProps = {|
  items: $ReadOnlyArray<{| relativeURL: string, label: string |}>,
|};

export const LowLevelJsonLD = ({ items }: JsonLDProps): React.Node => {
  const { origin } = useUserData();
  const { t, language } = useLocale();

  if (items.length === 0) {
    return null;
  }

  return (
    <StructuredData
      type="BreadcrumbList"
      properties={{
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: `${origin}/${language}`,
            name: t('estimateAndSell'),
          },
          ...items.map((item, pos) => ({
            '@type': 'ListItem',
            position: pos + 2,
            item: {
              '@id': origin + item.relativeURL,
              name: item.label,
            },
          })),
        ],
      }}
    />
  );
};

export const JsonLD = ({ items }: Props): React.Node => {
  const router = useRouter();
  return (
    <LowLevelJsonLD
      items={items
        .filter(x => x.isHome !== true)
        .map(item => ({
          label: item.label,
          relativeURL: router.getUrl(item.href).relative,
        }))}
    />
  );
};
