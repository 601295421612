// @flow

import * as React from 'react';
import { useTheme } from '../theme';

type Props = {|
  href?: string,
  onClick?: (SyntheticMouseEvent<>) => void,
  underline?: boolean,
  color?: ?string,
  children: React.Node,
  rel?: 'nofollow',
|};

const isModifiedEvent = (event: SyntheticMouseEvent<>): boolean => {
  return (
    event.metaKey || event.ctrlKey || event.shiftKey || event.altKey // triggers resource download
  );
};

export const A: React.AbstractComponent<Props, any> = React.forwardRef(
  ({ href, onClick, children, underline = true, color, rel }, ref) => {
    const { colors } = useTheme();
    return (
      <a
        ref={ref}
        href={href}
        rel={rel}
        onClick={e => {
          if (!isModifiedEvent(e)) {
            onClick?.(e);
          }
        }}
        css={{
          color: color ?? colors.highText,
          textDecoration: underline === false ? 'none' : 'underline',
        }}
      >
        {children}
      </a>
    );
  },
);
